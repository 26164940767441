/* Estilo global para asegurar que el cuerpo y html ocupen toda la pantalla */
body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  padding: 0;
  overflow: hidden;
}

/* Contenedor principal con la imagen de fondo */
.login-container {
  background-image: url('../../.././public/resources/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Contenedor del formulario */
.login-form-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Título del formulario */
.login-form h2 {
  margin-bottom: 1.5rem;
  font-size: 1.8rem;
  color: #333;
  font-weight: 600;
}

/* Estilos de los campos de entrada */
.form-group {
  width: 100%;
  margin-bottom: 1.2rem;
}

.form-group label {
  display: block;
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 0.5rem;
}

.form-group input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #007bff;
  outline: none;
}

/* Estilos de error */
.input-error {
  background-color: rgb(255, 167, 167);
  border-color: #ff4d4d;
}

.message-error {
  color: #ff4d4d;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

/* Enlace de "Olvidé mi contraseña" */
.forgot-password {
  text-align: right;
}

.forgot-password a {
  font-size: 0.9rem;
  color: #007bff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

/* Botones */
.login-button,
.register-button {
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}

.login-button {
  background-color: #007bff;
  color: white;
}

.login-button:hover {
  background-color: #0056b3;
}

.register-button {
  background-color: #28a745;
  color: white;
}

.register-button:hover {
  background-color: #218838;
}

/* Indicador de carga */
.circular-progress {
  display: block;
  margin: 1rem auto;
}

/* Responsividad */
@media (max-width: 600px) {
  .login-container {
    align-items: flex-start;
    background-image : none;
    height: 100%;
  }

  .login-form-container {
    padding: 1.5rem;
  }

  .login-form h2 {
    font-size: 1.6rem;
  }

  .form-group input {
    font-size: 1rem;
  }

  .login-button,
  .register-button {
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  .forgot-password a {
    font-size: 0.8rem;
  }
}
