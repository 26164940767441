/* src/components/Sidebar.css */
.hamburger-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #4da6ff;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  z-index: 20;
}

/* Estado activo del botón */
.hamburger-btn.active {
  color: #0056b3; /* Cambiar el color si es necesario cuando está activo */
  background: none; /* Asegurarse de que no tenga color de fondo */
}
  
  /* Contenedor del menú */
  .menu {
    position: absolute;
    top: 3rem;
    right: 1rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    padding: 1rem;
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    z-index: 10;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    transform: scale(0.95);
    opacity: 0;
  }
  
  /* Mostrar el menú cuando esté abierto */
  .menu.open {
    display: flex;
    transform: scale(1);
    opacity: 1;
  }
  
  /* Estilos para los elementos del menú como enlaces */
  .menu-item {
    color: black;
    font-size: 1rem;
    padding: 0.3rem 0;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
  }
  
  /* Estilo para los iconos de menú */
  .menu-icon {
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  
  /* Efecto hover */
  .menu-item:hover {
    color: #0056b3;
    transform: translateX(5px);
  }
  
  @media (max-width: 768px) {
    .menu.open {
      margin-top: -2rem;
    }/* Estilo básico para el botón hamburguesa */
  .hamburger-btn {
      background: none;
      border: none;
      font-size: 2rem; /* Tamaño grande para facilitar clic en dispositivos pequeños */
      color: #4da6ff;
      cursor: pointer;
      padding: 0.5rem;
      position: absolute;
      top: 1rem;
      right: 1rem;
      z-index: 20;
    }
   
    /* Estado activo del botón */
    .hamburger-btn.active {
      color: #0056b3; /* Cambiar el color si es necesario cuando está activo */
      background: none; /* Asegurarse de que no tenga color de fondo */
    }

    
    /* Menú desplegable oculto por defecto */
    .menu {
      position: absolute;
      top: 4rem; /* Ajuste según la altura del botón */
      right: 0;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
      padding: 1rem;
      display: none; /* Oculto por defecto */
      flex-direction: column;
      gap: 0.5rem;
      z-index: 10;
      transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
      transform: scale(0.95);
      opacity: 0;
    }
    
    /* Menú visible cuando está abierto */
    .menu.open {
      display: flex;
      transform: scale(1);
      opacity: 1;
    }
    
    /* Estilo para los elementos del menú */
    .menu-item {
      color: black;
      font-size: 1.2rem; /* Tamaño adecuado para móviles */
      padding: 0.5rem 0;
      cursor: pointer;
      transition: color 0.3s ease, transform 0.2s ease;
      display: flex;
      align-items: center;
      text-decoration: none;
      font-weight: 500;
    }
    
    /* Efecto hover sobre los elementos */
    .menu-item:hover {
      color: #0056b3;
      transform: translateX(5px);
    }
    
    /* Estilos para los íconos del menú */
    .menu-icon {
      margin-right: 0.5rem;
    }
    
    /* Media Queries para tabletas */
    @media (max-width: 768px) {
      .hamburger-btn {
        font-size: 1.8rem; /* Ajustar tamaño del ícono hamburguesa */
      }
    
      .menu {
        top: 3.5rem; /* Ajustar la distancia superior */
        right: 0.5rem; /* Ajustar la distancia derecha */
      }
    
      .menu-item {
        font-size: 1rem; /* Reducir tamaño de los elementos del menú */
      }
    }
    
    /* Media Queries para móviles */
    @media (max-width: 480px) {

      .hamburger-btn {
        background: none;
        border: none;
        font-size: 2rem; /* Tamaño grande para facilitar clic en dispositivos pequeños */
        color: #4da6ff;
        cursor: pointer;
        padding:0.75rem;
        position: absolute;
        top: 1rem;
        right: 1rem;
        z-index: 20;
      }
     
      /* Estado activo del botón */
      .hamburger-btn.active {
        color: #0056b3; /* Cambiar el color si es necesario cuando está activo */
        background: none; /* Asegurarse de que no tenga color de fondo */
      }
    
      .menu {
        top: 4rem;
        right: 0.5rem;
        width: 80%; /* Hacer que el menú ocupe más espacio */
        padding: 1rem; /* Ajustar el padding */
      }
    
      .menu-item {
        font-size: 1rem; /* Tamaño de fuente más grande para móviles */
      }
    }
    
    .menu {
      top: 7rem;
    }
  }
  
  @media (max-width: 480px) {
    .menu.open {
      margin-top: -2rem;
    }
    .menu {
      top: 7rem;
    }
  }
  