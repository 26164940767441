/* Estilos generales para .site-details */
.site-details {
  max-width: 100%;
  margin: 40px auto;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  background-color: #f0f8ff;
  font-family: "Arial", sans-serif;
  color: #333;
  overflow-y: auto;
  height: 96%;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

u p {
  font-size: 1.2rem;
  color: #5a5a5a;
  font-style: italic;
  margin-bottom: 25px;
}

p {
  font-weight: bold;
}

label {
  font-weight: 500;
}

.buttonsOptions button {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Ajustes para los iconos */
.icon {
  font-size: 1.2em;
}

.buttonsOptions {
  display: flex;
  justify-content: space-around; /* Distribuye los botones en una fila */
  align-items: center;
  margin-top: 20px;
}
/* Estilo para cada botón */
button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  transition: background-color 0.3s ease;
}

.share-button {
  background-color: #007bff;
  color: #fff;
}

.share-button:hover {
  background-color: #0056b3;
}

.how-button {
  background-color: #28a745;
  color: #fff;
}

.how-button:hover {
  background-color: #218838;
}

.cancel-request-btn {
  background-color: #ff6b6b;
  color: #fff;
}

.cancel-request-btn:hover {
  background-color: #e63946;
}

.loading {
  text-align: center;
  font-size: 1.5rem;
  color: #333;
  font-weight: 500;
}

/* Detalles de la sección de recursos */
.resources-section {
  margin-top: 30px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
}

.resources-section h3 {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.resources-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.resource-item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.resource-item label {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  accent-color: #4caf50;
  margin: 0.5rem;
}

input[type="checkbox"]:checked + label {
  font-weight: bold !important;
}

.checked {
  font-weight: bold !important;
}

/* Sección de comentarios */
.comments-section {
  margin-top: 40px;
}

.comments-section h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

.comment-item {
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  font-size: 1rem;
}

.comment-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.comment-input {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  resize: vertical;
  font-size: 1rem;
  background-color: #f5f5f5;
}

.comment-button {
  background-color: #4caf50;
  color: white;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: 16rem;
}

.comment-button:hover {
  background-color: #45a049;
}

.comment-form {
  margin-top: 20px;
}

.comment-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 1rem;
}

.comment-button {
  padding: 10px 15px;
  border: none;
  background-color: #28a745;
  color: white;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.comment-button:hover {
  background-color: #218838;
}

.comment-item {
  margin-bottom: 10px;
}

.comments-section {
  margin-top: 30px;
}

.comments-list {
  list-style-type: none; /* Elimina los puntos por defecto */
  padding: 0;
}

.comment-item {
  background-color: #f9f9f9; /* Fondo suave */
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.comment-header {
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
}

.comment-date {
  color: #666;
  font-size: 1rem;
  margin-left: 10px;
  font-weight: 500;
}

.delete-comment-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff4d4d;
  font-size: 1rem;
  margin-left: 10px;
}

.delete-comment-btn:hover {
  color: #cc0000;
}
.comment-message {
  margin-top: 5px;
  font-size: 1em;
  color: #444;
  font-weight: 500;
}

/* Media Queries para Tablet */
@media (max-width: 768px) {
  .site-details {
    padding: 20px;
    margin: 20px auto;
  }

  h2 {
    font-size: 1.8rem;
  }

  u p {
    font-size: 1.1rem;
  }

  .resources-section h3,
  .comments-section h3 {
    font-size: 1.3rem;
  }

  .resources-list {
    gap: 8px;
  }

  .comment-input {
    font-size: 0.9rem;
  }

  .comment-button {
    font-size: 0.9rem;
    padding: 8px 16px;
    align-self: center;
  }
}

/* Media Queries para Móvil */
@media (max-width: 480px) {
  .site-details {
    padding: 15px;
    margin: 10px auto;
    height: 90%
  }

  h2 {
    font-size: 1.6rem;
  }

  u p {
    font-size: 1rem;
  }

  .resources-section h3,
  .comments-section h3 {
    font-size: 1.2rem;
  }

  .resources-list {
    flex-direction: column;
    gap: 6px;
  }

  .comment-input {
    font-size: 0.85rem;
  }

  .comment-button {
    font-size: 0.85rem;
    padding: 6px 12px;
    align-self: center;
  }

  .button-text {
    display: none; /* Oculta el texto del botón en pantallas pequeñas */
  }

  .icon {
    font-size: 1.5em; /* Ajusta el tamaño del icono en móvil */
  }

  .buttonsOptions {
    justify-content: space-around;
  }
}
