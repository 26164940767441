/* src/components/PrivacyPolicy.css */
.privacy-policy-container {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    overflow-y: auto;
    height: 95%;
  }
  
  .privacy-policy-container h1 {
    font-size: 24px;
    color: #0056b3;
    margin-bottom: 16px;
  }
  
  .privacy-policy-container h2 {
    font-size: 20px;
    color: #333;
    margin-top: 24px;
    margin-bottom: 8px;
  }
  
  .privacy-policy-container p,
  .privacy-policy-container ul {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
  }
  
  .privacy-policy-container ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  