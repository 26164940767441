/* Estilos globales */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5f5f5;
  color: #333;
  font-family: Arial, sans-serif;
  width: 100%;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
}

.top-container {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  background-color: white;
  color: #fff;
  align-items: center;
}

/* Título y logo centrado */
.title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #4da6ff;
  text-shadow: 1px 1px 0px black;
  justify-content: center;
  width: 100%;
}

.logo {
  width: 50px;
  height: auto;
}

.title-text {
  font-size: 2.2rem;
  color: #4da6ff;
}

/* Leyenda */
.legend {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem;
  margin-top: 3rem;
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  font-size: 0.9rem;
  color: black;
  position: absolute;
  top: 6rem;
  left: 1.5rem;
  z-index: 10;
}

.legend-item {
  display: flex;
  align-items: center;
}

.legend-item img {
  margin-right: 0.5rem;
  width: 24px;
  height: 24px;
}

/* Contenedor de mapas */
.map-container {
  flex-grow: 1;
  width: 100%;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  padding-top: 0;
  height: calc(100vh - 5rem);
}

/* Botón personalizado */
.sidebar-btn {
  height: 2rem;
  align-self: flex-end;
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd, sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 0.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s, background-position 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.sidebar-btn:active,
.sidebar-btn:focus {
  outline: none;
}

.sidebar-btn:hover {
  background-position: -20px -20px;
}

.sidebar-btn:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 0.125em;
}



/* Media Queries para Tablet */
@media (max-width: 768px) {
  .map-container{
    overflow-y: auto;
  }
  .top-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .title {
    justify-content: center;
  }

  .title-text {
    font-size: 1.8rem;
  }

  .legend {
    top: 7rem;
    left: 1rem;
    font-size: 0.8rem;
  }

  .sidebar-right {
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .map-container {
    height: calc(100vh - 8rem);
  }
}

/* Media Queries para Móvil */
@media (max-width: 480px) {
  .map-container{
    overflow-y: auto;
  }
  .top-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .title {
    font-size: 1rem;
    gap: 0.25rem;
  }

  .title-text {
    font-size: 1.5rem;
  }

  .legend {
    top: 9rem;
    left: 0.5rem;
    font-size: 0.7rem;
  }

  .legend-item img {
    width: 20px;
    height: 20px;
  }

  .sidebar-right {
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .sidebar-right button {
    padding: 0.4rem 0.8rem;
    font-size: 0.8rem;
  }

  .map-container {
    height: calc(100vh - 9rem);
  }
}