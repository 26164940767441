/* src/components/CancelationRequestPage.css */
.cancelation-request-page {
    padding: 20px;
    max-width: 600px;
    margin: auto;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .btnSendCancel {
    padding: 10px 20px;
    background-color: #4da6ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btnSendCancel:hover {
    background-color: #3380cc;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  