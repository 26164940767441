/* src/components/CookieConsent.css */
.cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 1rem;
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .cookie-consent p {
    margin: 0;
    padding: 0 1rem;
  }
  
  .cookie-consent a {
    color: #00aaff;
    text-decoration: underline;
  }
  
  .accept-button {
    background-color: #00aaff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    margin-left: 1rem;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .accept-button:hover {
    background-color: #0077cc;
  }
  