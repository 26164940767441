.about-us-container {
    max-width: 98%;
    margin: 50px auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    overflow-y: auto; /* Permite desplazamiento si el contenido es más grande */
    box-sizing: border-box;
    height: 88%;
    /* Eliminar height: 100vh para permitir que el contenedor crezca según el contenido */
}

.about-us-container h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.about-us-container h2 {
    font-size: 2rem;
    margin-top: 30px;
    color: #2c3e50;
}

.about-us-container p {
    font-size: 1rem;
    margin-bottom: 20px;
}

.about-us-description {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

/* Disposición del equipo */
.team-members {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
}

.team-member {
    width: 45%;
    text-align: center;
}

.team-member-img {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 20px;
}

.team-member h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.team-member p {
    font-size: 1rem;
    color: #7f8c8d;
}

.about-us-container strong {
    font-weight: bold;
}

/* Estilo para el logo de LinkedIn */
.linkedin-logo {
    width: 50px;  /* Ajusta el tamaño del logo */
    height: auto;
    margin-top: 10px;
  }
  
  /* Estilo para el enlace de LinkedIn */
  .linkedin-link {
    display: inline-block;
    margin-top: 10px;
  }

/* Media Queries para Tablet */
@media (max-width: 768px) {
    p{
        text-align: center;
    }
    h2{
        text-align: center;
    }
    .about-us-container {
        padding: 15px;
    }

    .about-us-container h1 {
        font-size: 2rem;
        margin-bottom: 15px;
    }

    .about-us-container h2 {
        font-size: 1.75rem;
    }

    .team-members {
        flex-direction: column;
        align-items: center;
    }

    .team-member {
        width: 70%;
        margin-bottom: 20px;
    }

    .team-member-img {
        width: 130px;
        height: 130px;
    }
}

/* Media Queries para Móvil */
@media (max-width: 480px) {
    p{
        text-align: center;
    }
    h2{
        text-align: center;
    }
    .about-us-container {
        padding: 10px;
        margin-bottom: 50px; /* Agregar un margen inferior para evitar que el contenido se corte */
    }

    .about-us-container h1 {
        font-size: 1.8rem;
    }

    .about-us-container h2 {
        font-size: 1.5rem;
    }

    .about-us-container p, 
    .about-us-description {
        font-size: 0.9rem;
    }

    .team-members {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .team-member {
        width: 90%;
    }

    .team-member-img {
        width: 100px;
        height: 100px;
    }

    .team-member h3 {
        font-size: 1.3rem;
    }

    .team-member p {
        font-size: 0.9rem;
    }
}
