/* CreateSite.css */

/* Contenedor principal para alinear el mapa y el formulario */
.create-site-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100vh;
    padding: 20px;
}
.aviso{
    color: red;
}
h5{
    font-weight: 500;
    color: darkgrey;
    margin-bottom: 0.5rem;
    text-align: center;
    font-style: italic;
}

/* Mapa: ocupa la mayor parte del espacio */
.create-site-map {
    width: 60%;
    height: 100vh;
    background-color: #eee;
    position: relative; /* hace que el mapa se quede fijo al desplazarse */
    top: 0; /* fija el mapa al comienzo de la ventana */
}

/* Contenedor del formulario */
.create-site-form-container {
    width: 39%;
    height: 95%;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.create-site-form-container h2{
    margin-bottom: 1rem;
}

/* Agrupar los campos relacionados con la dirección en un recuadro */
.address-group {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
}

/* Estilos del formulario */
.create-site-form {
    display: flex;
    flex-direction: column;
}

/* Ajustar los campos de texto y select */
.form-group {
    margin-bottom: 15px;
}

.address-container h3{
    margin-bottom: 1rem;
}

/* Estilo de las etiquetas */
.form-group label {
    font-size: 14px;
    margin-bottom: 5px;
}

/* Estilo común para los campos de entrada (inputs, selects, textareas) */
.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Ajustar el tamaño de los textareas */
.form-group textarea {
    resize: vertical;
    height: 100px;
}

/* Para los campos que deben estar en la misma fila (número y código postal) */
.form-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* Estilo específico para los inputs de número y código postal */
.form-row input {
    width: 48%; /* Ajustar para que queden dos en la misma fila */
}

/* Estilo del botón de envío */
.submit-btn {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

/* Estilo al pasar el mouse sobre el botón de envío */
.submit-btn:hover {
    background-color: #0056b3;
}

/* Estilo del botón de geocodificación */
.geocode-btn {
    padding: 10px 15px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
}

/* Estilo al pasar el mouse sobre el botón de geocodificación */
.geocode-btn:hover {
    background-color: #218838;
}

/* Estilos para los iconos de OpenLayers */
.ol-zoom, .ol-overlaycontainer-stopevent {
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

/* Estilo para dispositivos móviles */
@media (max-width: 768px) {
    .create-site-container {
        flex-direction: column;
        overflow-y: auto;
        height: 88%;
        
    }

    .create-site-form-container {
        flex: none;
        max-height: 38vh; /* Altura máxima para el formulario */
        overflow-y:visible;
        width:100%
    }

    .create-site-map {
        flex: none;
        max-height: 38vh; /* Altura máxima para el mapa */
        overflow-y: auto;
        width: 100%;
    }

    .create-site-container {
        display: flex;
        justify-content: stretch;
    }

    .ol-viewport{
        height: 90%;
    }
}