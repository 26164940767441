.register-container {
  background-image: url('../../.././public/resources/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.register-form {
  margin: 1rem;
}

.register-form-container {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
}

.register-form h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.form-group-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.error-input {
  border-color: red;
  background-color: #ffe6e6;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.register-actions {
  text-align: center;
}

.register-button {
  width: 100%;
  padding: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #218838;
}

.register-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.login-link {
  text-align: center;
  margin-top: 20px;
}

.login-link a {
  color: #007bff;
  text-decoration: none;
}

.login-link a:hover {
  text-decoration: underline;
}

/* Media Queries para Tablet */
@media (max-width: 768px) {

  .form-group-group {
    grid-template-columns: 1fr; /* Cambia a una columna */
  }

  .register-form-container {
    width: 90%; /* Reduce el ancho en tablets */
  }

  .register-button {
    font-size: 15px;
    padding: 12px;
  }
}

/* Media Queries para Móvil */
@media (max-width: 480px) {

  .register-container {
    padding: 10px;
    height: auto;
  }

  .register-form-container {
    padding: 15px;
    width: 95%;
  }

  .register-form h2 {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .form-group label {
    font-size: 14px;
  }

  .form-group input {
    font-size: 14px;
    padding: 8px;
  }

  .error-message {
    font-size: 13px;
  }

  .register-button {
    padding: 10px;
    font-size: 14px;
  }
  
  .login-link {
    font-size: 14px;
  }
}
