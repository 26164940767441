.my-sites-container {
  padding: 5px;
  text-align: center;
  max-height: 93vh;
  overflow-y: auto;
}

h2 {
  margin-bottom: 20px;
  color: #4da6ff;
  font-size: 2rem;
}

/* Tabla de ubicaciones */
.locations-table {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
  background-color: #f9f9f9;
}

.locations-table th, .locations-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  font-size: 1rem;
}

.locations-table th {
  background-color: #4da6ff;
  color: white;
}

/* Ajuste de anchos de columnas */
.locations-table th:nth-child(1), .locations-table td:nth-child(1) {
  width: 5%;
}

.locations-table th:nth-child(2), .locations-table td:nth-child(2) {
  width: auto;
}

.locations-table th:nth-child(3), .locations-table td:nth-child(3) {
  width: 25%;
}

.locations-table th:nth-child(4), .locations-table td:nth-child(4) {
  width: auto;
}

.locations-table th:nth-child(5), .locations-table td:nth-child(5) {
  width: 20%;
}

/* Botones generales */
button {
  padding: 8px 16px; /* Unificación del padding */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem; /* Tamaño de fuente consistente */
  transition: background-color 0.3s ease;
  margin: 0; /* Asegúrate de que no haya márgenes adicionales */
}

/* Botón de Ver */
.view-btn {
  background-color: #4da6ff;
  margin: 0.5rem;
}

.view-btn:hover {
  background-color: #007bb5;
}

/* Botón de Editar */
.edit-btn {
  background-color: lightslategray;
  margin: 0.5rem;
}

.edit-btn:hover {
  background-color: #5a5a5a;
}

/* Botón de Eliminar */
.delete-btn {
  background-color: lightcoral;
  margin: 0.5rem;
}

.delete-btn:hover {
  background-color: #c0392b;
}

/* Mensaje de error */
.error-message {
  color: red;
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 20px;
}

.action-icon {
  font-size: 2rem;
}

/* Responsividad para tablets */
@media (max-width: 768px) {
  .locations-table {
    text-align-last: center;
  }
  .locations-table th, .locations-table td {
    font-size: 0.9rem;
    padding: 8px;
  }

  .my-sites-container {
    max-height: 74vh;
  }

  h2 {
    font-size: 1.6rem;
  }

  .delete-btn span,
  .edit-btn span {
    display: none; /* Ocultar el texto */
  }

  button {
    padding: 8px; /* Ajustar padding si es necesario */
    font-size: 1rem; /* Tamaño de ícono un poco más grande */
  }

  .locations-table th:nth-child(1),
  .locations-table td:nth-child(1),
  .locations-table th:nth-child(4),
  .locations-table td:nth-child(4) {
    display: none;
  }

  /* Ajustar el tamaño de las columnas visibles */
  .locations-table th:nth-child(2), .locations-table td:nth-child(2) {
    width: 60%;
  }

  .locations-table th:nth-child(3), .locations-table td:nth-child(3) {
    width: 40%;
  }
}

/* Responsividad para móviles */
@media (max-width: 480px) {

  .my-sites-container {
    max-height: 87vh;
  }
  .locations-table {
    text-align-last: center;
  }

  .locations-table th, .locations-table td {
    font-size: 0.8rem;
    padding: 5px;
  }

  h2 {
    font-size: 1.4rem;
  }

  /* Ocultar algunas columnas menos esenciales */
  .locations-table th:nth-child(1),
  .locations-table td:nth-child(1),
  .locations-table th:nth-child(4),
  .locations-table td:nth-child(4) {
    display: none;
  }

  /* Ajustar el tamaño de las columnas visibles */
  .locations-table th:nth-child(2), .locations-table td:nth-child(2) {
    width: 60%;
  }

  .locations-table th:nth-child(3), .locations-table td:nth-child(3) {
    width: 40%;
  }

  .delete-btn span,
  .edit-btn span {
    display: none; /* Ocultar el texto */
  }

  button {
    padding: 8px; /* Mantén los botones con el mismo tamaño en móviles */
    font-size: 1rem; /* Tamaño de ícono consistente */
  }

  /* Ajustar el tamaño de los iconos */
  .action-icon {
    font-size: 1rem;
  }
}
