/* Estilo para el popup */
.ol-popup {
  background-color: white;
  border: 1px solid black;
  padding: 1rem;
  box-shadow: 0 0 0 0.2rem black;
  border-radius: 8px;
  max-width: 300px; /* Limita el ancho máximo */
}

/* Ocultar el popup */
.ol-popup.hidden {
  opacity: 0;
  pointer-events: none;
}

/* Mostrar el popup */
.ol-popup.visible {
  opacity: 1;
}

/* Triángulo en el popup */
.ol-popup::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.popUpMap {
  text-align: left;
}

.popUpMap h3 {
  margin: 0 0 5px;
}

.popUpMap p {
  margin: 2px 0;
  color: #333;
  font-size: 14px;
}

/* Botón personalizado */
.point-button {
  margin-top: 1rem;
  height: 2rem;
  align-self: flex-end;
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd, sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 0.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.2s, background-position 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.point-button:active,
.point-button:focus {
  outline: none;
}

.point-button:hover {
  background-position: -20px -20px;
}

/* Media Queries para Tablet */
@media (max-width: 768px) {
  .ol-popup {
    padding: 0.8rem;
    max-width: 260px;
  }

  .popUpMap p {
    font-size: 13px;
  }

  .point-button {
    font-size: 0.9rem;
    padding: 5px 18px;
  }
}

/* Media Queries para Móvil */
@media (max-width: 480px) {
  .ol-popup {
    padding: 0.5rem;
    max-width: 220px;
  }

  .popUpMap h3 {
    font-size: 1rem;
  }

  .popUpMap p {
    font-size: 12px;
  }

  .point-button {
    font-size: 0.7rem;
    padding: 7px 16px;
  }
}
