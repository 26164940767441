/* ContactUs.css */
.contact-us-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  p{
    margin: 2rem;
  }
  
  .contact-us-container h1 {
    text-align: center;
    color: #4da6ff;
  }
  
  .contact-us-container p {
    text-align: center;
    color: #333;
    font-size: 1rem;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 0.8rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    outline: none;
    border-color: #4da6ff;
  }
  
  .btnSend {
    padding: 0.8rem 1.2rem;
    background-color: #4da6ff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .btnSend:hover{
    background-color: #3380cc;
  }
  
  .btnSend:disabled {
    background-color: #b0d0f5;
    cursor: not-allowed;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .success-message {
    color: green;
    text-align: center;
    margin-bottom: 1rem;
  }

