/* src/components/EmergencyAlert/EmergencyAlert.css */
.emergency-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .emergency-alert {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .emergency-alert h2 {
    color: #d9534f;
  }
  
  .emergency-alert p {
    margin: 15px 0;
    font-size: 16px;
    line-height: 1.4;
  }
  
  .close-button {
    background: #d9534f;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .close-button:hover {
    background: #c9302c;
  }
  